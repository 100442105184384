<template>
  <v-container fluid>
    <Header
      :status="filter.status"
      :active-count="activeCount"
      :archived-count="archivedCount"
      @update-status="updateFilterStatus"
      @create-new-tag="onCreateTag"
    />

    <v-card
      class="py-6 px-6 mt-3"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <template v-if="(hasNoTags && isLoading) || hasTags">
        <v-row
          justify="space-between"
          class="align-center"
        >
          <v-col
            cols="12"
            md="auto"
            class="d-flex align-center"
          >
            <SearchComponent
              :search="filter.name"
              :placeholder="$t('placeHolder.searchByName')"
              @update:search="filter.name = $event"
            />

            <FilterDialog @update-filter-types="updateFilterTypes" />
          </v-col>

          <v-col
            cols="12"
            md="auto"
          >
            <v-row
              justify="end"
              class="align-center"
            >
              <SettingsMenu :filter-items="filteredMenuHeaders" />
            </v-row>
          </v-col>
        </v-row>

        <TagTable
          :headers="filteredHeaders"
          :items="filteredTags"
          item-key="uid"
          @edit-tag="onEditTag"
          @archive-tag="onArchiveTag"
          @delete-tag="onDeleteTag"
        />
      </template>

      <EmptyTag
        v-else
        @create-new-tag="onCreateTag"
      />

      <CreateUpdateDialog
        v-model="showCreateUpdateDialog"
        :data="selectedTag"
        @create-new-tag="createTag"
        @update-tag="updateTag"
        @close-dialog="showCreateUpdateDialog = false"
      />

      <ArchiveConfirmDialog
        v-model="showArchiveConfirmDialog"
        :tag-name="selectedTag.name"
        :filter-status="filter.status"
        @archive-tag="archiveTag"
      />

      <DeleteConfirmDialog
        v-model="showDeleteConfirmDialog"
        :tag="selectedTag"
        @delete-tag="deleteTag"
      />
    </v-card>
  </v-container>
</template>

<script>
import * as _ from "lodash";
import { createNamespacedHelpers } from "vuex";

import makeTagService from "@/services/api/tag";

import Header from "@/components/Admin/Tag/Header";
import FilterDialog from "@/components/Admin/Tag/FilterDialog.vue";
import SearchComponent from "@/components/Project/SearchComponent.vue";
import SettingsMenu from "@/components/Project/SettingsMenu.vue";
import TagTable from "@/components/Admin/Tag/TagTable.vue";
import EmptyTag from "@/components/Admin/Tag/EmptyTag.vue";
import CreateUpdateDialog from "@/components/Admin/Tag/CreateUpdateDialog.vue";
import ArchiveConfirmDialog from "@/components/Admin/Tag/ArchiveConfirmDialog.vue";
import DeleteConfirmDialog from "@/components/Admin/Tag/DeleteConfirmDialog.vue";

import { handleNetworkStatusError } from "@/mixins/redirect";
import { showSuccessToast, showErrorToast } from "@/utils/toast";

const { mapState } = createNamespacedHelpers("user");

export default {
  name: "Tags",

  components: {
    Header,
    FilterDialog,
    SearchComponent,
    SettingsMenu,
    TagTable,
    EmptyTag,
    CreateUpdateDialog,
    ArchiveConfirmDialog,
    DeleteConfirmDialog,
  },
  mixins: [handleNetworkStatusError],

  data() {
    return {
      filter: {
        name: "",
        status: "active",
        entityTypes: [],
      },
      originalTags: [],
      filteredTags: [],
      headers: [
        {
          text: this.$t("name"),
          align: "start",
          sortable: true,
          value: "name",
          class: "elevation-0 rounded-l-lg",
          checked: true,
        },
        {
          text: this.$t("description"),
          value: "description",
          sortable: true,
          checked: true,
        },
        {
          text: this.$t("tagPage.type"),
          value: "entityTypes",
          sortable: true,
          checked: true,
        },
        {
          text: this.$t("tagPage.count"),
          value: "count",
          sortable: true,
          width: 100,
          checked: true,
        },
        {
          text: "",
          value: "uid",
          sortable: false,
          class: "rounded-r-lg",
          width: 60,
          checked: true,
        },
      ],
      isLoading: false,
      showCreateUpdateDialog: false,
      showArchiveConfirmDialog: false,
      showDeleteConfirmDialog: false,
      selectedTag: {
        uid: "",
        name: "",
        description: "",
        entityTypes: [],
      },
    };
  },

  computed: {
    ...mapState(["currentAccount"]),
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.value != "actions");
      return filtered;
    },
    filteredHeaders() {
      return this.headers.filter((header) => {
        const filterItem = this.filteredMenuHeaders.find(
          (item) => item.text === header.text
        );
        return filterItem ? filterItem.checked : true;
      });
    },
    activeCount() {
      return this.originalTags.filter((item) => !item.archivedAt).length;
    },

    archivedCount() {
      return this.originalTags.filter((item) => !!item.archivedAt).length;
    },

    hasTags() {
      return this.originalTags.length > 0;
    },
    hasNoTags() {
      return this.originalTags.length === 0;
    },
  },

  watch: {
    "filter.name": {
      handler: _.debounce(function () {
        this.filterTags();
      }, 500),
    },
  },

  async mounted() {
    await this.init(this.currentAccount.handle);
  },
  async beforeRouteUpdate(to, from, next) {
    const handle = to.params.handle;
    if (handle && handle !== from.params.handle) {
      try {
        await this.init(handle);
        next();
      } catch (error) {
        next();
      }
    } else {
      next();
    }
  },

  methods: {
    async init(handle) {
      await this.getTags(handle);
    },
    async getTags(handle) {
      const tagService = makeTagService(this.$api);

      try {
        this.isLoading = true;
        const response = await tagService.getTags(handle);
        if (response.status == 200) {
          this.originalTags = response.data;
        }
      } catch (err) {
        this.redirectOnError(err.response.status);
        showErrorToast(this.$swal, "fetchError", { item: "tags" });
      } finally {
        this.isLoading = false;
      }

      this.filterTags();
    },

    updateFilterStatus(value) {
      this.filter.status = value;

      this.filterTags();
    },

    updateFilterTypes(value) {
      this.filter.entityTypes = value;

      this.filterTags();
    },

    filterTags() {
      let filteredTags = _.cloneDeep(this.originalTags);

      if (this.filter.name) {
        const searchName = this.filter.name.toLowerCase();
        filteredTags = filteredTags.filter((item) =>
          item.name.toLowerCase().includes(searchName)
        );
      }

      if (this.filter.status) {
        filteredTags = filteredTags.filter(
          (item) =>
            (this.filter.status === "active" && !item.archivedAt) ||
            (this.filter.status === "archived" && item.archivedAt)
        );
      }

      if (this.filter.entityTypes.length > 0) {
        filteredTags = filteredTags.filter((item) =>
          this.filter.entityTypes.some((tagType) => {
            return item.entityTypes && item.entityTypes.includes(tagType.name);
          })
        );
      }

      this.filteredTags = filteredTags;
    },

    onCreateTag() {
      this.selectedTag = {
        uid: "",
        name: "",
        description: "",
        entityTypes: [],
      };

      this.showCreateUpdateDialog = true;
    },

    async createTag(tag) {
      this.showCreateUpdateDialog = false;

      const tagService = makeTagService(this.$api);
      this.isLoading = true;
    
      try {
        const payload = {
          name: tag.name,
          description: tag.description,
          entityTypes: tag.entityTypes,
        }

        const response = await tagService.createTag(this.currentAccount.handle, payload);
        this.originalTags.push(response.data);
        showSuccessToast(this.$swal, "createSuccess", { item: "Tag" });
      } catch (err) {
        showErrorToast(this.$swal, "createError", { item: "Tag" });
      } finally {
        this.isLoading = false;
      }

      this.filterTags();
    },

    onEditTag(tag) {
      this.selectedTag = {
        uid: tag.uid,
        name: tag.name,
        description: tag.description,
        entityTypes: tag.entityTypes,
        archivedAt: tag.archivedAt,
      };

      this.showCreateUpdateDialog = true;
    },

    async updateTag(tag) {
      this.showCreateUpdateDialog = false;
      const tagService = makeTagService(this.$api);
      this.isLoading = true;
      const sentData = {
        ...tag,
        entityTypes: tag.entityTypes,
      }
      try {
        const response = await tagService.updateTag(this.currentAccount.handle, sentData);

        this.originalTags = this.originalTags.map((item) => {
          if (item.uid !== tag.uid) {
            return item;
          }

          return response.data;
        });
        showSuccessToast(this.$swal, "updateSuccess", { item: "Tag" });
      } catch (err) {
        showErrorToast(this.$swal, "updateError", { item: "Tag" });
        throw new Error(err);
      } finally {
        this.isLoading = false;
      }

      this.filterTags();
    },

    onArchiveTag(tag) {
      this.selectedTag = {
        uid: tag.uid,
        name: tag.name,
        description: tag.description,
        entityTypes: tag.entityTypes,
        archived: this.filter.status === "active" ? true : new Date(),
      };

      this.showArchiveConfirmDialog = true;
    },

    async archiveTag() {
      this.showArchiveConfirmDialog = false;
      try {
        await this.updateTag(this.selectedTag);
        showSuccessToast(this.$swal, "archiveSuccess", { item: "Tag" });
      } catch (err) {
        showErrorToast(this.$swal, "archiveError", { item: "Tag" });
      }
    },

    onDeleteTag(tag) {
      this.selectedTag = {
        uid: tag.uid,
        name: tag.name,
        description: tag.description,
        entityTypes: tag.entityTypes,
        count: tag.count,
        archived: tag.archivedAt,
      };

      this.showDeleteConfirmDialog = true;
    },

    async deleteTag() {
      this.showDeleteConfirmDialog = false;
      const tagService = makeTagService(this.$api);

      try {
        await tagService.deleteTag(this.currentAccount.handle, this.selectedTag.uid);
        this.originalTags = this.originalTags.filter(
          (item) => item.uid !== this.selectedTag.uid
        );
        showSuccessToast(this.$swal, "deleteSuccess", { item: "Tag" });
      } catch (err) {
        showErrorToast(this.$swal, "deleteError", { item: "Tag" });
      } finally {
        this.isLoading = false;
      }

      this.filterTags();
    },
  },
};
</script>
